import CountriesEnums from "@/enums/CountriesEnums";
import SpecialtiesEnums from "@/enums/SpecialtiesEnums";
import EventsEnums from "@/enums/EventsEnums";
import OffersEnums from "@/enums/OffersEnums";

export default [
    {
        header: 'Utilities',
    },
    {
        title: CountriesEnums.MODULE_TITLE,
        route: 'countries',
        icon: 'MapIcon',
    },
    {
        title: 'Host Your Course',
        route: 'host-your-course',
        icon: 'MapIcon',
    },
    {
        title: SpecialtiesEnums.MODULE_TITLE,
        route: 'specialities',
        icon: 'ActivityIcon',
    },
    {
        title: EventsEnums.MODULE_TITLE,
        route: 'events',
        icon: 'LayersIcon',
    },
    {
        title: OffersEnums.MODULE_TITLE,
        route: 'offers',
        icon: 'DollarSignIcon',
    },
    {
        title: 'config',
        route: 'config',
        icon: 'MonitorIcon',
    },
    {
        title: 'About Us',
        route: 'about-us',
        icon: 'HelpCircleIcon',
    },
    {
        title: 'Terms & Conditions',
        route: 'terms-and-conditions',
        icon: 'HelpCircleIcon',
    },
    {
        title: 'Privacy Policy',
        route: 'privacy-policy',
        icon: 'HelpCircleIcon',
    }
]
