import CoursesEnums from "@/enums/CoursesEnums";

export default [
  {
    header: 'Study Section',
  },
  {
    title: CoursesEnums.MODULE_TITLE,
    route: 'dashboard-courses-list',
    icon: 'LayersIcon',
  },
  {
    title: 'Lectures',
    route: 'dashboard-lectures-list',
    icon: 'BookIcon',
  },
  {
    title: 'Notes',
    route: 'dashboard-notes-list',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Questions & Answers',
    route: 'dashboard-questions-list',
    icon: 'BookmarkIcon',
  },
  {
    title: 'Flash Cards',
    route: 'dashboard-flash-cards-list',
    icon: 'BarChart2Icon',
  },
  {
    title: 'Categories',
    route: 'dashboard-categories-list',
    icon: 'DatabaseIcon',
  },
  // {
  //   title: 'Host Course Requests',
  //   route: 'host-course-request',
  //   icon: 'DivideSquareIcon',
  // },
]
