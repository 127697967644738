import CoursesEnums from "@/enums/CoursesEnums";

export default [
  {
    header: 'Users Management',
  },
  {
    title: "Users",
    route: 'dashboard-users-list',
    icon: 'UsersIcon',
  },
]
